<template>
  <div class="">
    <button
      class="table-action-button bg-white mr-2"
      @click.stop="openExportProductModal"
      style="font-size: 12px"
    >
      <i class="fa-solid mr-2 fa-file-export fa-flip-horizontal"></i> Export
    </button>
    <ModalVue
      ref="ExportModal"
      id="ExportModal"
      title="<span class='mr-2'>Export your products in Quizell.</span>"
      size="md"
    >
      <div class="mx-2">
        <div class="">
          <b-form-radio
            name="export-category"
            v-model="category"
            value="all"
            class="radio-button my-2"
          >
            All Products
          </b-form-radio>
          <b-form-radio
            :disabled="
              !(
                exportProductFilter.status ||
                exportProductFilter.title ||
                exportProductFilter.tag ||
                exportProductFilter.collection_ids
              )
            "
            name="export-category"
            v-model="category"
            value="filtered"
            class="radio-button my-2"
          >
            Filtered Products
          </b-form-radio>
          <b-form-radio
            name="export-category"
            v-model="category"
            :disabled="checkProducts"
            value="selected"
            class="radio-button my-2"
          >
            Selected Products
          </b-form-radio>
        </div>
        <div class="">
          <p
            style="
              color: var(--Gray-500, #667085);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            "
          >
            Your products and their detailed data will be provided in a CSV
            file.
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <Button
            :is-loading="isFileDownload.isLoading"
            @click="$emit('export', category)"
            >Export</Button
          >
        </div>
      </div>
    </ModalVue>
  </div>
</template>
<script>
export default {
  props: {
    isFileDownload: Object,
    exportProductFilter: {
      type: Object,
      default: () => ({
        status: "",
        title: "",
        tag: "",
        collection_ids: [],
        productIds: [],
      }),
    },
  },
  components: {
    ModalVue: () =>
      import(/* webpackChunkName: "ModalVue" */ "../../Layout/Modal.vue"),
    Button: () =>
      import(/* webpackChunkName: "Button" */ "../../Layout/Button.vue"),
  },

  data() {
    return {
      category: "all",
    };
  },
  computed: {
    checkProducts() {
      return this.exportProductFilter.productIds.length <= 0;
    },
  },
  methods: {
    toast(text) {
      this.$toasted.show(text, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
    openExportProductModal() {
      this.category = "all";
      this.$refs.ExportModal.open();
    },
    closeShopifyProductModal() {
      this.$refs.ExportModal.close();
    },
  },
};
</script>
<style scoped>
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #ffa201;
  border-color: #ffa201;
}
.radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
</style>
